.Login {
  width: 300px;
  padding: 2%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media all and (min-width: 480px) {
  .Login {
    width: 300px;
    position: fixed;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
